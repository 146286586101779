
































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Icons} from '@/shared/components/icon/icons';
import RsOnboardingFormSignup from '@/modules/onboarding/components/forms/signup.vue';
import RsOnboardingSocialLogin from '@/modules/onboarding/components/auth/social-login.vue';
import {OnboardingRouter} from '@/modules/onboarding/router';
import RsJobsSocialLogin from '@/modules/jobs/components/social-login.vue';

@Component({
  name: 'rs-modal-signup',
  components: {RsJobsSocialLogin, RsOnboardingSocialLogin, RsOnboardingFormSignup, RsIcon},
})
export default class RSLoading extends Vue {
  @Prop() open: boolean;

  Icons = Icons;
  OnboardingRouter = OnboardingRouter;
  env = process.env;

  @Watch('open')
  onOpenChange(show: boolean) {
    if (!show) {
      window.removeEventListener('keyup', this.onEscapeKeyUp);
    } else {
      window.addEventListener('keyup', this.onEscapeKeyUp);
    }
  }

  onEscapeKeyUp(event: any) {
    if (event.which === 27) {
      this.close();
    }
  }

  close() {
    this.$emit('close');
  }
}
